<template>
  <v-container fluid fill-height class="d-flex align-start grey lighten-3">
    <v-row class="grey lighten-3">
      <v-col cols="12">
        <v-text-field
          label="Buscar producto"
          append-icon="mdi-magnify"
          class="white"
          outlined
          rounded
          hide-details="auto"
        />
      </v-col>
      <v-col cols="12">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th>Producto</th>
                <th>Precio</th>
                <th>Exi. Tienda</th>
                <th>Exi. Bodega</th>
                <th>Venta</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in products" :key="item.name">
                <td>
                  <v-row>
                    <v-col cols="3" class="d-flex justify-center align-center">
                      <v-img
                        :src="require('@/assets/cajita.png')"
                        max-width="50px"
                        min-width="40px"
                        class="mx-auto"
                      />
                    </v-col>
                    <v-col cols="9" class="no-wrap">
                      <v-icon x-small>mdi-key</v-icon>{{ item.code }}
                      <br />
                      <v-icon x-small>mdi-package-variant</v-icon
                      ><span class="text-caption">{{ item.name }}</span>
                    </v-col>
                  </v-row>
                </td>
                <td>
                  <span class="text-caption">{{ item.price }}</span>
                </td>
                <td
                  :class="
                    item.tienda > 5
                      ? 'green'
                      : item.tienda > 1
                      ? 'yellow'
                      : 'red'
                  "
                >
                  {{ item.tienda }}
                </td>
                <td :class="item.bodega > 0 ? 'green' : 'red'">
                  {{ item.bodega > 0 ? item.time : "" }}
                </td>
                <td>
                  <v-checkbox color="green" hide-details></v-checkbox>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "About",
  data() {
    return {
      products: [
        {
          name: "Prod. 1",
          price: 850,
          code: "123456",
          tienda: 5,
          bodega: 10,
          time: "30min",
        },
        {
          name: "Prod. 2",
          price: 950,
          code: "123457",
          tienda: 6,
          bodega: 0,
          time: "1hr",
        },
        {
          name: "Prod. 3",
          price: 1050,
          code: "123458",
          tienda: 7,
          bodega: 6,
          time: "1hr",
        },
        {
          name: "Prod. 4",
          price: 1150,
          code: "123459",
          tienda: 4,
          bodega: 2,
          time: "1hr",
        },
        {
          name: "Prod. 5",
          price: 1250,
          code: "123460",
          tienda: 0,
          bodega: 1,
          time: "20min",
        },
      ],
      // Aquí puedes inicializar tus datos
    };
  },
  methods: {
    // Aquí puedes definir tus métodos
  },
  mounted() {
    // Aquí puedes realizar acciones después de que la vista se haya montado
  },
};
</script>

<style scoped>
/* Aquí puedes agregar estilos específicos para esta vista */
</style>
